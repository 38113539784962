import React from 'react';
import {graphql, Link} from 'gatsby';
import Layout from '../layouts/Layout';
import Image from 'gatsby-image';
import styled from 'styled-components';
import BigTitle from '../components/shared/text/BigTitle';
import Section from '../components/shared/wraps/Section/Section';
import Content from '../components/shared/Content';
import ReactHtmlParser from 'react-html-parser';
import SEO from '../components/seo';
import { Wrapper } from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import {
  RotatingLetter,
  NoWrap,
} from 'src/components/shared/Animations/Animations';
import FadeLink from '../components/shared/navigations/FadeLink/FadeLink';
import useLng from "src/hooks/useLng";
import {useI18next} from "gatsby-plugin-react-i18next";

const StyledImage = styled(Image)`
  margin: 0;
  max-width: 100% !important;
`;

const StyledBigTitle = styled(BigTitle)`
  margin-bottom: 3.5rem;
  text-align: center;
  width: 100%;
  font-size: clamp(6rem, 8vw, 12rem);
  line-height: 1;
`;

const StyledBanner = styled.div`
  max-height: 22rem;
  margin-bottom: clamp(6rem, 8vw, 10rem);
  background-color: ${({ theme }) => theme.colors.black2};

  @media screen and (max-width: 991px) {
    max-height: unset;
  }
`;

const ProjectFeatures = styled.ol`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: flex-end;
  height: 100%;
  counter-reset: features-counter;
  list-style: none;
  padding: 6rem;

  @media screen and (max-width: 991px) {
    align-items: flex-start;
    flex-direction: column;
    padding: 3rem;
  }
`;

const SingleFeatureOne = styled.li`
  flex-basis: 100%;
  font-size: 3rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  counter-increment: features-counter;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  line-height: 1;

  &::before {
    content: counter(features-counter) '.';
    font-size: 8rem;
    color: ${({ theme }) => theme.colors.main};
    margin-right: 2rem;
    line-height: 0.7;
  }

  @media screen and (max-width: 991px) {
    &:not(:last-child) {
      margin-bottom: 1.4rem;
    }

    &::before {
      display: flex;
      justify-content: flex-end;
      width: 5rem;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 2.4rem;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &::before {
      font-size: 6rem;
    }
  }
`;

const SingleFeature = styled.li`
  flex-basis: 33.333%;
  font-size: 3rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  counter-increment: features-counter;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  line-height: 1;

  &::before {
    content: counter(features-counter) '.';
    font-size: 8rem;
    color: ${({ theme }) => theme.colors.main};
    margin-right: 2rem;
    line-height: 0.7;
  }

  @media screen and (max-width: 991px) {
    &:not(:last-child) {
      margin-bottom: 1.4rem;
    }

    &::before {
      display: flex;
      justify-content: flex-end;
      width: 5rem;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 2.4rem;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &::before {
      font-size: 6rem;
    }
  }
`;

const StyledSection = styled(Section)`
  padding-left: 0;
  padding-right: 0;
`;

const splitText = text => {
  const splittedText = text.split(' ');
  const lastWord = splittedText.slice(-1)[0];

  return [
    splittedText.slice(0, -1).join(' ') + ' ',
    lastWord.slice(0, -1),
    lastWord.slice(-1),
  ];
};

const Project = ({ data, location: { pathname: path } }) => {
  const {
    gallery,
    description,
    slug,
    title,
    feature1,
    feature2,
    feature3,
    project_link,
      translations
  } = data.directusProjekty;
  const {t} = useLng();
  const {language} = useI18next();
  const [restOfTitle, lastWordExceptLastLetter, lastLetter] = splitText(title);
  const en = translations.find(el => el.jezyk === "en")
  const justOne = feature1 && !feature2 && !feature3;
  return (
    <Layout path={path} id={slug}>
      <SEO title={`Projekt - ${title}`} />
      <StyledSection nonGrid site nonFlex first>
        <Wrapper nonGrid>
          <StyledBigTitle>
            {restOfTitle}
            <NoWrap>
              {lastWordExceptLastLetter}
              <RotatingLetter delay={0.4}>{lastLetter}</RotatingLetter>
            </NoWrap>
          </StyledBigTitle>
        </Wrapper>
        {language === "pl" ? (
            <>{[feature1, feature2, feature3].some(item => item) ? (
                <StyledBanner>
                  <ProjectFeatures>
                    {justOne ? <SingleFeatureOne>{feature1}</SingleFeatureOne> : <>
                    {!!feature1 && <SingleFeature>{feature1}</SingleFeature>}
                    {!!feature2 && <SingleFeature>{feature2}</SingleFeature>}
                    {!!feature3 && <SingleFeature>{feature3}</SingleFeature>}
                    </>}
                  </ProjectFeatures>
                </StyledBanner>
            ) : null}
            </>
        ) : (
            <>{en && [en.feature1, en.feature2, en.feature3].some(item => item) ? (
                <StyledBanner>
                  <ProjectFeatures>
                    {justOne ? <SingleFeatureOne>{en.feature1}</SingleFeatureOne> : <>
                      {!!en.feature1 && <SingleFeature>{en.feature1}</SingleFeature>}
                      {!!en.feature2 && <SingleFeature>{en.feature2}</SingleFeature>}
                      {!!en.feature3 && <SingleFeature>{en.feature3}</SingleFeature>}
                    </>}
                  </ProjectFeatures>
                </StyledBanner>
            ) : null}
            </>
        ) }
        <Wrapper nonGrid>
          <Content>{ReactHtmlParser(description)}</Content>
          {gallery.map(img => (
              <>
                {!!project_link ? <a href={project_link || ''} target="_blank">
                  <StyledImage
                      alt={img.title}
                      fluid={img.localFile.childImageSharp.fluid}
                  />
                </a> : <StyledImage
                    alt={img.title}
                    fluid={img.localFile.childImageSharp.fluid}
                />}
              </>
          ))}
          {project_link ? (
            <div
              style={{
                textAlign: 'center',
                fontSize: '2rem',
                marginTop: '5rem',
              }}
            >
              <FadeLink
                stylish
                as="a"
                target="_blank"
                rel="noreferrer noopener nofollow"
                href={project_link || ''}
              >
                {t("zobaczOnline")}
              </FadeLink>
            </div>
          ) : null}
        </Wrapper>
      </StyledSection>
    </Layout>
  );
};

export const query = graphql`
  query projectsQuery($id: String!) {
    directusProjekty(id: { eq: $id }) {
      title
      slug
      description
      feature1
      feature2
      feature3
      project_link
      gallery {
        title
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
        translations {
        jezyk
      feature3
      feature2
      feature1
    }
    }
  }
`;

export default Project;
